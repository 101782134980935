import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import Gap from "../elements/gap.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const Images = makeShortcode("Images");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Nebula</h4>
  <h5>A haptic wayfinding for the visually impaired</h5>
  <br />
  2021 <br />
  IxDA Interaction awards 2022 finalist
  <br />
  Collaboration: <a href="https://www.simondogger.nl/">Simon Dogger</a>
  <br />
  Role: Designer & Software Developer
  <br />
  Keywords: wearable technology, e-textile, GPS, compass
  <iframe src="https://player.vimeo.com/video/665646886?h=232a30762a" width="100%" height="700" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    </TextLayout>
    <PhotoGallery photos={[{
      src: "../../../nebula/poster3.jpg",
      width: 2,
      height: 1
    }, {
      src: "../../../nebula-closeup1.jpg",
      width: 2,
      height: 1
    }, {
      src: "../../../nebula/electronics1.jpg",
      width: 3,
      height: 1
    }, {
      src: "../../../nebula/electronics2.jpg",
      width: 3,
      height: 1
    }, {
      src: " ../../../nebula/improvement1.jpg",
      width: 4,
      height: 1
    }]} mdxType="PhotoGallery" />
    <Gap margin={100} mdxType="Gap" />
    <TextLayout mdxType="TextLayout">
      <b>Introduction</b>
      <Text mdxType="Text">
  Travelling and exploring unfamiliar places is an exciting experience for
  sighted people. However, for the visually impaired and blind people, this
  experience is often more frustrating and mentally demanding than enjoyable.
  <br />
  <br />
  <b>
    By translating navigational data into haptic sensation, Nebula supports
    unfamiliar urban exploration for people with visual impairment.
  </b> It allows users to explore without constantly figuring out where they are
  going or from where they come. Nebula minimises the fear of getting lost and relieves
  mental strain by creating a haptic breadcrumb trail to ensure the wearer always
  gets home.
      </Text>
      <Title mdxType="Title">
  <b>Exploration</b>
      </Title>
      <img src={"../../../nebula/interaction1.jpg"} />
      <Annotation mdxType="Annotation">Different ways of turnings on and off the device</Annotation>
      <img src={"../../../nebula/interaction2.jpg"} />
      <Annotation mdxType="Annotation">
  Different button sizes and interactions of setting a waypoint
      </Annotation>
      <img src={"../../../nebula/cases.jpg"} />
      <Annotation mdxType="Annotation">Trying different electronic cases</Annotation>
      <img src={"../../../nebula/samples.png"} />
      <Annotation mdxType="Annotation">
  Trying out different types of fabrics and design of belts
      </Annotation>
      <img src={"../../../nebula/variations2.jpg"} />
      <Annotation mdxType="Annotation">Variation of belts</Annotation>
      <Title mdxType="Title">
  <b>Technical Design</b>
      </Title>
      <Text mdxType="Text">
  The device translates navigational data, distance and direction into vibration
  point and frequency. Supported codes are in {""}
  <a href="https://github.com/void-kuangyi/Nebula/blob/master/final_demo_day.ino">
    nebula github repository
  </a>.
      </Text>
      <Images mdxType="Images">
  <img src={"../../../nebula/electronics3.jpg"} />
  <Annotation mdxType="Annotation">
    Arduino Nano, 7 vibration motors, Compass (Beitan 880q), GPS (Beitan 880q),
    Battery
  </Annotation>
  <img src={"../../../nebula/electronics4.jpg"} />
  <Annotation mdxType="Annotation">Connecting the belt and the buckle</Annotation>
  <img src={"../../../nebula/electronics5.jpg"} />
  <Annotation mdxType="Annotation">Layout of vibration motors on the belt</Annotation>
      </Images>
      <Images mdxType="Images">
  <img src={"../../../nebula/making1.jpg"} />
  <Annotation mdxType="Annotation">
    Sewing electronics with conductive yarn onto the fabric
  </Annotation>
  <img src={"../../../nebula/making2.jpg"} />
  <Annotation mdxType="Annotation">
    Connecting conductive yarns through connector to the buckle
  </Annotation>
      </Images>
    </TextLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      